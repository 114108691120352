const backendDomin = "http://backend.heraextrabio.com:8080"

const SummaryApi = {
    signUP : {
        url : `${backendDomin}/api/signup`,
        method : "post"
    },
    signIn : {
        url : `${backendDomin}/api/signin`,
        method : "post"
    },
    current_user : {
        url : `${backendDomin}/api/user-details`,
        method : "get"
    },
    logout_user : {
        url : `${backendDomin}/api/userLogout`,
        method : 'get'
    },
    allUser : {
        url : `${backendDomin}/api/all-user`,
        method : 'get'
    },
    addOrder : {
        url : `${backendDomin}/api/add-order`,
        method : 'post'
    },
    getAllOrders : {
        url : `${backendDomin}/api/get-all-orders`,
        method : 'get'
    },
    getOrderById : {
        url : `${backendDomin}/api/get-order-by-id/`,
        method : 'get'
    },
    getOrderByUserId : {
        url : `${backendDomin}/api/get-order-by-userId/`,
        method : 'get'
    },
    updateUser : {
        url : `${backendDomin}/api/update-user`,
        method : "post"
    },
    addProduct : {
        url : `${backendDomin}/api/add-product`,
        method : 'post'
    },
    getAllProducts : {
        url : `${backendDomin}/api/get-all-products`,
        method : 'get'
    },
    updateProduct : {
        url : `${backendDomin}/api/update-product`,
        method  : 'post'
    },
    deleteProduct : {
        url : `${backendDomin}/api/delete-product`,
        method  : 'post'
    },
    getOneProductForEachCategory : {
        url : `${backendDomin}/api/one-product-for-each-category`,
        method : 'get'
    },
    getProductsByCategory : {
        url : `${backendDomin}/api/get-products-by-category`,
        method : 'post'
    },
    productDetails : {
        url : `${backendDomin}/api/product-details`,
        method : 'post'
    },
    addToCartProduct : {
        url : `${backendDomin}/api/addtocart`,
        method : 'post'
    },
    addToCartProductCount : {
        url : `${backendDomin}/api/countAddToCartProduct`,
        method : 'get'
    },
    addToCartViewProduct: {
        url : `${backendDomin}/api/view-card-product`,
        method : 'get'
    },
    updateCartProduct : {
        url : `${backendDomin}/api/update-cart-product`,
        method : 'post'
    },
    deleteCartProduct : {
        url : `${backendDomin}/api/delete-cart-product`,
        method : 'post'
    },
    searchProduct : {
        url : `${backendDomin}/api/search-product`,
        method : 'get'
    },
    filterProduct : {
        url : `${backendDomin}/api/filter-product`,
        method : 'post'
    },
    updateOrderStatus : {
        url : `${backendDomin}/api/update-order-status`,
        method : 'post'
    },
    getRecentOrders : {
        url : `${backendDomin}/api/get-recent-orders`,
        method : 'get'
    },
    mostOrderedProducts : {
        url : `${backendDomin}/api/most-ordered-products`,
        method : 'get'
    },
    getOrdersKPI : {
        url : `${backendDomin}/api/get-orders-kpi`,
        method : 'get'
    },
    getUsersKPI : {
        url : `${backendDomin}/api/get-users-kpi`,
        method : 'get'
    },
    getMonthsRevenues : {
        url : `${backendDomin}/api/get-months-kpi`,
        method : 'get'
    },
    getCategoriesKPI : {
        url : `${backendDomin}/api/get-categories-kpi`,
        method : 'get'
    },
    fetchUserSuggestion : {
        url : `${backendDomin}/api/get-user-suggestion/`,
        method : 'get'
    }
}


export default SummaryApi
