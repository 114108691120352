// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-area-table {
    background: var(--secondary-color);
    border-radius: 5px;
    box-shadow: var(--light-shadow1);
    padding: 12px 16px;
    overflow-x: auto;
  }
  
  .userTable {
    width: 100%;
    border-collapse: collapse;
    color: var(--text-color-inverted);
  }
  
  .userTable thead {
    background-color: var(--thead-bg-color);
    color: var(--text-color-inverted);
  }
  
  .userTable th, .userTable td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .userTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .userTable tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  
  .userTable tbody tr:hover {
    background-color: var(--hover-bg-color);
  }
  
  .userTable .action-buttons button {
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .userTable .action-buttons button:hover {
    opacity: 0.9;
  }
  
  .userTable .action-buttons .edit {
    background-color: var(--edit-button-bg-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .userTable .action-buttons .edit svg {
    color: green; /* Couleur verte */
  }`, "",{"version":3,"sources":["webpack://./src/pages/AllUsers.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,iCAAiC;EACnC;;EAEA;IACE,uCAAuC;IACvC,iCAAiC;EACnC;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,uCAAuC;EACzC;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,6CAA6C;IAC7C,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,YAAY,EAAE,kBAAkB;EAClC","sourcesContent":[".content-area-table {\r\n    background: var(--secondary-color);\r\n    border-radius: 5px;\r\n    box-shadow: var(--light-shadow1);\r\n    padding: 12px 16px;\r\n    overflow-x: auto;\r\n  }\r\n  \r\n  .userTable {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    color: var(--text-color-inverted);\r\n  }\r\n  \r\n  .userTable thead {\r\n    background-color: var(--thead-bg-color);\r\n    color: var(--text-color-inverted);\r\n  }\r\n  \r\n  .userTable th, .userTable td {\r\n    padding: 12px 15px;\r\n    text-align: left;\r\n  }\r\n  \r\n  .userTable tbody tr:nth-child(even) {\r\n    background-color: #f9f9f9;\r\n  }\r\n  \r\n  .userTable tbody tr:nth-child(odd) {\r\n    background-color: #ffffff;\r\n  }\r\n  \r\n  .userTable tbody tr:hover {\r\n    background-color: var(--hover-bg-color);\r\n  }\r\n  \r\n  .userTable .action-buttons button {\r\n    padding: 8px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n  }\r\n  \r\n  .userTable .action-buttons button:hover {\r\n    opacity: 0.9;\r\n  }\r\n  \r\n  .userTable .action-buttons .edit {\r\n    background-color: var(--edit-button-bg-color);\r\n    color: #fff;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  .userTable .action-buttons .edit svg {\r\n    color: green; /* Couleur verte */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
